import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Tap from "@tapfiliate/tapfiliate-js";
import axios from "axios";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";
// Images
import HighFiveImage from "assets/outer-pages/svg/High-Five.svg";

import * as C from "./LeadsSurvey.style";

const LeadsSurveyForm = ({ coach, isCoachSection, onModalClose }) => {
  const [CurrentStep, setCurrentStep] = useState(1);

  const [SelectedStepOne, setSelectedStepOne] = useState(null);
  const [SelectedStepTwo, setSelectedStepTwo] = useState(null);
  const [SelectedStepThree, setSelectedStepThree] = useState(null);
  const [SelectedStepFour, setSelectedStepFour] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Message, setMessage] = useState("");
  const [IsSending, setIsSending] = useState(false);
  const [EmailHasSent, setEmailHasSent] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isTrainerLeadSaving, setIsTrainerLeadSaving] = useState(false);
  const [ formError, setFormError ] = useState(null)

  const STEP_ONE = [
    { id: 1, title: "Gewichtsabnahme" },
    { id: 2, title: "Muskelaufbau" },
    { id: 3, title: "Gesunder Lebensstil" },
  ];

  const STEP_TWO = [
    { id: 1, title: "Männlich" },
    { id: 2, title: "Weiblich" },
    { id: 3, title: "Keine Angabe" },
  ];

  const STEP_THREE = [
    { id: 1, title: "18-21 Jahre" },
    { id: 2, title: "22-25 Jahre" },
    { id: 3, title: "26-35 Jahre" },
    { id: 4, title: "36-45 Jahre" },
    { id: 5, title: "45+ Jahre" },
  ];

  useEffect(() => {
    if (isCoachSection) setCurrentStep(2)
  }, [])

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();

    // Tap.customer(Email);
  }, []);

  const onItemSelect = (step, selectedItem) => {
    if (step === 2) {
      setSelectedStepTwo(selectedItem);
    }

    if (step === 3) {
      setSelectedStepThree(selectedItem);
    }

    if (step === 4) {
      setSelectedStepFour(selectedItem);
    }
  };

  const onNext = (step) => {
    switch (step) {
      case 1:
        setCurrentStep(CurrentStep + 1);
        break;
      case 2:
        if (SelectedStepTwo !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 3:
        if (SelectedStepThree !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 4:
        if (SelectedStepFour !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 5:
        if (
          Message &&
          FirstName &&
          LastName &&
          PhoneNumber &&
          Email &&
          isCheckboxChecked
        ) {
          createTrainerLead();
        }
        break;
      default:
        break;
    }
  };

  const onPrev = (step) => {
    setCurrentStep(step - 1);
  };

  const createTrainerLead = async () => {
    setIsTrainerLeadSaving(true);
    const payload = {
      connected_trainer: coach?.trainerID || null,
      prename: FirstName,
      surename: LastName,
      mobilenumber: PhoneNumber,
      email: Email,
      lead_source: "Foodiary Lead",
      lead_received: getCurrentDateFormatted(),
      lead_contacted: "",
      lead_status: "received",
      lead_goal: SelectedStepTwo?.title,
      lead_gender: SelectedStepThree?.title,
      lead_age: SelectedStepFour?.title,
      lead_proposed_date: "",
      lead_informations: Message,
    };

    try {
      await axios.post(
        "https://app.foodiary.app/api/trainerLeads/create",
        payload,
        {
          headers: {
            "Api-Version": "v1",
            responseType: "application/json",
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-api-key":
              "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw",
          },
        }
      );

      setCurrentStep(CurrentStep + 1);
      createCustomerOnTapifiliate();
    } catch (error) {
      const errResponse = error.response.data
      console.log("got back an error : ", errResponse)

      let isEmailErrorMessage = errResponse?.error?.email
      let errorMessage = isEmailErrorMessage
        ? 'Deine E-Mail Adresse wurde bereits eingetragen. Bitte kontaktiere mich persönlich per E-Mail.'
        : 'An error accured, please try again.'
      
      console.log('error message : ', errorMessage)
      setIsTrainerLeadSaving(false)
      return setFormError(errorMessage)
    }
  };

  const createCustomerOnTapifiliate = async () => {
    const payload = {
      customer_id: Email,
      referral_code: "ogmwmdy",
      meta_data: {
        fullname_coach: coach ? coach?.prename + " " + coach?.surname : null,
        email_coach: coach ? coach?.email : null,
        vorname_klient: FirstName,
        nachname_klient: LastName,
        email_klient: Email,
        FranchiseID: coach ? coach?.FranchiseID : null,
      },
      user_agent: navigator.userAgent,
      ip: ipAddress,
      landing_page: window.localStorage.href,
    };

    try {
      const response = await axios.post(
        "https://api.tapfiliate.com/1.6/customers/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "3acc178a1eff97b14cc1b12c00222e070a3a1b91",
          },
        }
      );
      console.log("Response:", response);
      setIsTrainerLeadSaving(false);
    } catch (error) {
      setIsTrainerLeadSaving(false);
      console.error("Error:", error);
    }
  };

  const getCurrentDateFormatted = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <C.SurveyForm className="lead-form">
      <C.SurveyFormCard isCoachSection={isCoachSection}>
        <div className="inner-box">
          {CurrentStep === 1 && (
            <div>
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl mb-less">
                Vereinbare deinen Termin
              </C.SurveyFormTitle>

              <p className="ct-text-block text-dark text-lg text-center">
                Beantworte mir 3 kurze Fragen, sodass ich mich <br /> auf unser
                Gespräch gut vorbereiten kann.
              </p>

              <div className="plan-box-icons-wrap">
                <C.PlanBoxIcons>
                  {[
                    "Kennenlernen inkl. Anamnese und persönlichen Ernährungsplan",
                    "Tipps & Tricks für deine einfache Ernährungsumstellung.",
                    "Bis zu 100% Kostenrückerstattung der Krankenkasse auf unser präventives Angebot",
                  ].map((t) => (
                    <li key={t}>
                      <C.PlanBoxIconBox>
                        <img src={GreenTickWhiteBg} alt="Tick" />
                      </C.PlanBoxIconBox>
                      <C.PlanBoxInformation className="ct-text-block text-lg">
                        {t}
                      </C.PlanBoxInformation>
                    </li>
                  ))}
                </C.PlanBoxIcons>
              </div>

              <button
                className="btn-dark w-max mx-auto mb-2 mt-3"
                onClick={() => onNext(1)}
              >
                Weiter
              </button>
            </div>
          )}
          {CurrentStep === 2 && (
            <>
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                Wie lautet deine Zielsetzung?
              </C.SurveyFormTitle>
              {isCoachSection ? (
                <p className="ct-text-block text-dark text-lg text-center">
                  Beantworte mir 3 kurze Fragen, sodass ich mich <br /> auf unser
                  Gespräch gut vorbereiten kann.
                </p>
              ) : ''}
              {STEP_ONE.map((item) => (
                <C.FormItem
                  active={SelectedStepTwo?.id === item?.id}
                  onClick={() => onItemSelect(2, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button
                  className="btn-dark w-max cursor-pointer"
                  onClick={() => onNext(2)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}
          {CurrentStep === 3 && (
            <>
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                Wie identifizierst du dich?
              </C.SurveyFormTitle>
              {STEP_TWO.map((item) => (
                <C.FormItem
                  active={SelectedStepThree?.id === item?.id}
                  onClick={() => onItemSelect(3, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button className="btn-prev" onClick={() => onPrev(2)}>
                  Zurück
                </button>
                <button
                  className="btn-dark w-max cursor-pointer"
                  onClick={() => onNext(3)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}
          {CurrentStep === 4 && (
            <>
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                Wie alt bist du?
              </C.SurveyFormTitle>
              {STEP_THREE.map((item) => (
                <C.FormItem
                  active={SelectedStepFour?.id === item?.id}
                  onClick={() => onItemSelect(4, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button className="btn-prev" onClick={() => onPrev(3)}>
                  Zurück
                </button>
                <button
                  className="btn-dark w-max cursor-pointer"
                  onClick={() => onNext(4)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}

          {CurrentStep === 5 && (
            <>
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl">
                Wie erreichen wir dich?
              </C.SurveyFormTitle>

              { formError ? <p className="error">{formError}</p> : '' }

              <C.PhoneNumberInput
                type="text"
                placeholder="Vorname"
                value={FirstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <C.PhoneNumberInput
                type="text"
                placeholder="Nachname"
                value={LastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <C.PhoneNumberInput
                type="text"
                placeholder="Kontaktnummer"
                value={PhoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <C.EmailInput
                type="email"
                placeholder="E-Mail"
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <C.Textarea
                type="text"
                placeholder="Was möchtest du uns noch mitteilen?"
                rows={5}
                value={Message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <br />
              <br />
              <div className="checkbox-group d-flex gap-2">
                <label class="container-checkbox">
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  />
                  <span class="checkmark"></span>
                </label>
                <p className="my-0">
                  Ich habe die{" "}
                  <a
                    className=""
                    href="https://foodiary.app/terms/agb-coaching/"
                    target="_blank"
                  >
                    AGB Foodiary Coaching
                  </a>{" "}
                  und die{" "}
                  <a
                    className=""
                    href="https://foodiary.app/terms/datenschutz/"
                    target="_blank"
                  >
                    Datenschutzbestimmungen
                  </a>{" "}
                  gelesen, verstanden und akzeptiere diese.
                </p>
              </div>
              <C.Actions>
                <button
                  className="btn-prev"
                  disabled={isTrainerLeadSaving}
                  onClick={() => onPrev(4)}
                >
                  Zurück
                </button>
                <button
                  className="btn-dark w-max cursor-pointer"
                  onClick={() => onNext(5)}
                  disabled={isTrainerLeadSaving}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}

          {CurrentStep === 6 && (
            <>
              <img
                className="lead-form-last-step-image"
                src={HighFiveImage}
                alt=""
              />
              <C.SurveyFormTitle className="ct-headline foodiary-h2 color-dark text-3xl mb-less">
                Ich werde mich melden.
              </C.SurveyFormTitle>

              <p className="ct-text-block text-dark text-lg text-center">
                In den nächsten 48 Stunden werde ich mich für eine <br />{" "}
                Terminvereinbarung bei dir melden.
              </p>
              {!isCoachSection ? (
                <>
                  <br />
                  <button
                  className="btn-dark w-max mx-auto mb-2 mt-3"
                  onClick={onModalClose}
                  >
                  Schließen
                  </button>
                </>
              ) : ''}

              {/* <C.MealPlanContainer>
                <p className="left-title text-base">UNTERSTÜTZ DURCH:</p>
                <C.RestaurauntsBox>
                  <div className="restaurant hover-float">
                    <img src={FoodIconOne} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconTwo} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconThree} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconFour} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconFive} alt="" />
                  </div>
                </C.RestaurauntsBox>
              </C.MealPlanContainer> */}

              {/* <C.Actions>
                <button className="btn-dark w-max cursor-pointer">
                  Schließen
                </button>
              </C.Actions> */}
            </>
          )}
        </div>
      </C.SurveyFormCard>
    </C.SurveyForm>
  );
};

export default LeadsSurveyForm;
